import React, { useState, useEffect } from "react"
import styled from "styled-components"
import produce from "immer"

import Drawer from "../layout/Drawer"
import ExpansionPanel from "../layout/ExpansionPanel"
import Loading from "../Loading"
import ColorBox from "../ColorBox";
import { postData } from '../../services/server'

const ColorsDrawer = (props) => {
    const [loading, setLoading] = useState(true)
    const [systems, setSystems] = useState([]);
    const [expanded, setExpanded] = useState("");

    useEffect(
        () => {
            setLoading(true);

            postData("/api/colors/systems", {},
                (result) => {
                    setSystems(result);
                    setExpanded(result[0].name)
                },
                (error, status) => {
                    alert("Error loading color options.")
                },
                () => {
                    setLoading(false);
                }
            ); 
        }, 
        []
    );

    const handleExpand = (panel, isExpanded) => {
        setExpanded(isExpanded ? panel : false);

        if (isExpanded) {
            const systemId = systems.find(s => s.name == panel).id;

            postData("/api/colors/data", { colorsystem_id: systemId },
                function(result) {
                    setSystems(produce(draft => {
                        const index = draft.findIndex(s => s.id == systemId);
                        draft[index].colors = result;
                    }));
                },
                function(error) {
                    alert("An error occured while loading the colors.");
                },
                function() {
                }
            );
        }
    };

    return (
        <Drawer 
            title={props.title}
            loading={loading}
            onClose={props.onClose}
        >   
            {!loading &&
                <>
                {systems.filter(s => s.id > 0 && s.parts.indexOf(props.part.id) != -1).map((system, index) => (
                    <ExpansionPanel 
                        key={index} 
                        title={system.name}
                        expanded={expanded === system.name} 
                        onChange={(isExpanded) => handleExpand(system.name, isExpanded)}
                    >
                        {(system.colors && system.colors.length) ?
                            <div style={{display: "flex", flexFlow: "wrap"}}>
                                {system.colors.filter(c => c.parts.indexOf(props.part.id) != -1).map((color, index) => (
                                    <ColorBox 
                                        key={index}
                                        color={color}
                                        tooltip={color.name}
                                        onClick={() => props.onColor(color)}
                                    />
                                ))}
                            </div>
                        :
                            <Loading style={{margin: "0px auto"}} />
                        }
                    </ExpansionPanel>   
                ))} 
                </>        
            }
        </Drawer>
    )
}

export default ColorsDrawer