import React, { useState, useEffect, useContext, useRef } from "react"
import styled from "styled-components"
import produce from 'immer'
import { navigate } from "gatsby"
import queryString from 'query-string'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faArrowDown, faArrowUp, faCaretSquareLeft, faCaretSquareRight, faCircle, faPencilAlt, faSave, faShoppingCart, faUser } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";

import Container from "../components/layout/Container"
import PageHeader from "../components/layout/PageHeader"
import SEO from "../components/seo"
import Layout2Columns from "../components/layout/Layout2Columns";
import MajorError from "../components/MajorError"
import SignDetails from "../components/SignDetails"
import SelectField from "../components/fields/SelectField"
import ColorsDrawer from "../components/drawers/ColorsDrawer"

import { formatPrice } from "../utilities/utils"
import { postData } from '../services/server'
import Button from "../components/buttons/Button";
import HoverButton from "../components/buttons/HoverButton";
import GoogleImage from "../components/GoogleImage"
import EmailField from '../components/fields/EmailField';

import { CartContext } from '../state/CartContext';
import { colors, devices, storage } from "../settings";
import ShipDate from "../components/ShipDate";
import Breadcrumb from "../components/layout/Breadcrumb";
import { useCachedState } from "../hooks/useCachedState";
import { isValidSignContent } from "../utilities/utils";

const SignMenu = styled.ul`
  flex: 0;
  flex-basis: 270px;
  margin: 0px 0px 25px 0px;
  padding: 0px 15px 15px 15px;
  list-style-type: none;
  padding: 0px 0px 15px 0px;

  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 6px 10px #ccc;

  @media ${devices.mobile} {
    padding: 5px;
    margin: 10px;
  }
`
const MenuItem = styled.li`
  padding: 10px;
  border: 1px solid white;
  border-radius: 5px;
  margin: 5px;
  text-align: center;

  background-color: ${props => props.color ? colors[props.color] : "white"};
  color: black;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    margin-right: 5px;
  }

  a {
    color: white;
    flex-basis: 100%;
  }

  table {
      width: 100%;
      border-spacing: 5px;

      th {
          font-weight: normal;
          color: ${colors.orange};
          text-align: left;
      }
      td {
        text-align: left;
      }

      &.colors {
        width: auto;

        th, td {
          width: 60px;
          border-radius: 5px;
          vertical-align: bottom;
        }

        td {
          border: 1px solid #ccc;

          &:hover {
            border: 1px solid black;
          }
        }
      }
  }

  > div {
    > div {
      margin-top: 15px;

      > div {
        display: flex;

        svg {
          margin: 0px;
        }
        button {
          width: 60px;
        }
      }
    }
  }
`
const Price = styled.strong`
  font-size: 26px;
  font-weight: 400;
  color: black;
`
const SignImage = styled.div`
    margin-bottom: 0px;

    > div {
        height: ${isMobile ? 225:370}px;

        img {
            max-height: ${isMobile ? 200:330}px;
            display: block;
            margin: 0px auto;
        }
    }

    iframe {
      width: 100%;
      height: 350px;

      @media ${devices.mobile} {
        height: 200px;
      }      
    }

    button {
        border: 0px;
        background: none;        
        background-color: transparent;
        margin: 5px;
        padding: 0px;

        svg {
            font-size: 35px;
            color: #999;
        }

        &::-moz-focus-inner {
          border: 0;
          padding: 0;
        }        
    }
`
const Content = styled.div`
  border-top: 3px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-image: url(${storage.url}2020/tiles/grey3.jpg);
  padding-top: 25px;
  margin-top: 100px;
`
const SignImages = styled.div`
    display: flex;
    flex-flow: wrap;
    margin: 10px;

    img {
        height: 60px;
    }

    div {
        border: 2px solid white;
        margin: 0px 5px 5px 0px;
        height: 60px;
        overflow: hidden;
        cursor: pointer;
    }
    div.sign {
      max-width: 130px;
      overflow: hidden;
      display: flex;
      align-content: center;
      align-items: center;

      img {
        height: auto;
        max-height: 60px;        
      }
    }
    div.selected {
        border-color: ${colors.orange};
    }
`
const SignImagesNav = styled.nav`
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ccc;

    > * {
      margin: 5px;
      cursor: pointer;

      &:hover {
        color: #333;
      }

      &.selected {
        color: ${colors.orange} !important;
      }
    }
`

const SignImageTitle = styled.h4`
    margin: 0px;
    font-size: 100%;
    color: #666;
    padding: 5px;
    font-weight: normal;
    text-align: center;
`
const ProductMenu = styled.div`
    h4 {
      margin: 0px;  
      font-weight: normal;
    }
    > div {
      cursor: pointer;
      border: 2px solid white;
      display: flex;
      align-items: center;

      img {
        margin-right: 10px;
      }

      &.selected {
        border: 2px solid ${colors.orange};
      }
    }
`

const SignPage = (props) => {
  const cart = useContext(CartContext);
  const builderRef = useRef();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [busy, setBusy] = useState("");
  
  const [signUid, setSignUid] = useState(null);
  const [sign, setSign] = useState({});
  const [dirty, setDirty] = useState(false);
  const [contents, setContents] = useCachedState("construction", []);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState(null);
  const [selected, setSelected] = useState({mode:""});
  const [email, setEmail] = useState("");

  const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")
  const signLink = "https://signmonkey.com/sign/?" + (params.uid ? "uid=" + params.uid : params.id ? "id=" + params.id : "") ;

  const isTemplate = !sign || sign.uid == params.uid;

  const [data, setData] = useState({
		link: signLink,
		email: "",
  });
  const [errors, setErrors] = useState({
	});
  
  function onBuilderMessage(event) {
    const message = event.data;
    
    if (message.type == "signsaved") {
      const signData = message.data;

      if (busy == "edit" || busy == "editing") {
        // shouldn't be a template now that it has been saved
        window.location = "/build?uid=" + signData.uid + "&template=0"
      }
      else {
        setSign(produce(draft => {
          draft.id = signData.id;
          draft.uid = signData.uid;
          draft.estimate = signData.estimate;
        }));
        setBusy("");
        setDirty(false);
      }
    }
    else if (message.type == "signchanged") {
      let signData = message.data;

      signData.estimate = null;
      signData.colors = []
      //signData.sizes = [signData.groups[0].size]
      signData.size = parseFloat(signData.groups[0].size);
      
      for (const group of signData.groups) {
        const colorParts = group.parts.filter(p => p.color_ind == 1);

        for (const part of colorParts) {
          if (!Object.keys(signData.colors).find(p => p == part.type_name)) {
            signData.colors[part.type_name] = part;
          }
        }

        if (group.size > signData.size)
          signData.size = group.size;
      }

      if (signData.colors.face && signData.prop_nofacecolor_ind == 1)
        signData.colors.face = false;

      signData.product = signData.groups[0].product;
      
      if (!signData.sizes)
        signData.sizes = []

      if (signData.sizes.length == 0) {
        for (let i=(signData.product.min_size || 10); i<=(signData.product.max_size || 48); i++) {
          signData.sizes.push({
            size: i
          });
        }
      }

      setSign(signData);
    }
    else if (message.type == "saveerror") {
      alert("An error occured while saving the sign.");
      setBusy("save");
    }
  }

  function notifyBuilder(messageType, messageData) {        
    if ( builderRef && builderRef.current) {
        let message = {
            type: messageType,
            data: messageData,
        }
        
        builderRef.current.contentWindow.postMessage(message, "*");
    }
  }

  useEffect(
    () => {
        const params = queryString.parse(typeof window !== `undefined` ? window.location.search : "")

        if (params.uid)
          setSignUid(params.uid)

        if (!contents || contents.length === 0) {
            postData("/api/content/type", { type: "construction"},
                (result) => {
                    setContents(result.filter(c => c.optional_ind == 0));
                },
                (error) => {
                    console.log(error);
                }
            );
        }            
    }, 
    []
  );

  useEffect(
    () => {
      if (sign && sign.id) {
        let signImages = [{type: 'sign', title: "Sign Preview", image_url: sign.image || sign.preview_url, selected: true}];

        for (const content of contents) {
          if (isValidSignContent(sign.groups, content)) {
            signImages.push({...content, image_url: '2020/construction/' + content.image_url});
          }
        }

        setImages(signImages);
        setImage(signImages[0]);
      }
    }, 
    [sign, contents]
  );

  useEffect(() => {
    window.addEventListener("message", onBuilderMessage, false);    

    // clean up
    return () => window.removeEventListener("message", onBuilderMessage)
  })

  function onAddToCart() {
    setBusy("adding");

    cart.addSignToCart(sign.id, (cart) => {
        if (cart) {
            navigate("/checkout/?returnto=templates")
        }
        else {
          setError("An error occured adding the sign to your cart.")
        }

        setBusy("");
    })
  }

  function onImage(index) {
    setImages(produce(draft => {
        let curIndex = images.findIndex(i => i.selected);

        draft[curIndex].selected = false;
        draft[index].selected = true;
    }));
    setImage(images[index]);
  }
  function onNextImage() {
      let index = images.findIndex(i => i.selected);

      index++;
  
      if (index >= images.length)
          index = 0;

      onImage(index);
  }
  function onPrevImage() {
    let index = images.findIndex(i => i.selected);

    index--;
  
    if (index < 0)
        index = images.length - 1;

    onImage(index);
  }

  function onSignChange(prop, value) {
      setDirty(true);

      if (prop == "product") {
        notifyBuilder("product", { productId: value.parent_id || value.id });
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
      else
        notifyBuilder("change", { prop, value })
  }
  
  const pageTitle = isTemplate && sign.product ? sign.product.title : sign.id ? sign.title ? sign.title : "Sign Design #" + sign.id : "Loading..."
  const multiGroupSign = sign && sign.groups && sign.groups.length > 1;

  return (
    <>
      <SEO page="template" title={pageTitle} />

      <Container>
        <Breadcrumb items={[
            { url: "/", title: "Home"},
            { url: "/templates", title: "Sign Templates"},
            { url: sign.id && sign.pages && sign.pages.length ? "/pages/" + sign.pages[0].page_name : null, title: sign && sign.pages && sign.pages.length ? sign.pages[0].page_type + " Signs" : null }
          ]}
        />
      </Container>

      <Container>
        <Layout2Columns align="top" left={70} right={30}>
          <div>
            {!isMobile && 
              <PageHeader 
                title={pageTitle} 
              />
            }
            <div>
                <SignImage>
                    <div>
                        <div>
                            {signUid && 
                              <iframe 
                                ref={builderRef}
                                src={process.env.GATSBY_BUILDER_BASE_URL + "?uid=" + signUid + "&dimensions_ind=0#/preview"} 
                                title="Sign Preview" 
                                width="100%" 
                                height="100%" 
                                border="0" 
                                frameBorder="0" 
                                style={{
                                  position: !image || image.type == "sign" ? "initial" : "absolute",
                                  left: !image || image.type == "sign" ? "initial" : "-100000px",
                                }}
                              />
                            }
                            
                            {(image && image.type != "sign") &&
                              <>
                                <GoogleImage root src={image.image_url}  required lazy />
                                <SignImageTitle>{image.title}</SignImageTitle>
                              </>
                            }
                        </div>
                    </div>
                </SignImage>
            </div>

            {(images.length > 0) && 
              <SignImagesNav>
                <FontAwesomeSvgIcon icon={faCaretSquareLeft} onClick={onPrevImage} />
                {images.map((image, index) => (
                    <FontAwesomeSvgIcon icon={faCircle} key={index} onClick={() => onImage(index)} className={(image.type == "sign" ? "sign ":"") + (image.selected ? "selected":"")} />
                ))}
                <FontAwesomeSvgIcon icon={faCaretSquareRight} onClick={onNextImage} />
              </SignImagesNav>
            }

            {!isMobile && 
              <SignImages>
                  {images.map((image, index) => (
                      <div key={index} onClick={() => onImage(index)} className={(image.type == "sign" ? "sign ":"") + (image.selected ? "selected":"")}>
                          <GoogleImage root src={image.image_url} required lazy />
                      </div>
                  ))}
              </SignImages>
            }
          </div>
          <div>
          {sign.id && 
            <>                    
              {(busy == "save" || busy == "edit" || busy == "saving" || busy == "editing" ) ? 
                <div style={{border: "1px solid #ccc", borderRadius: "5px", padding: "15px", margin: isMobile ? "10px":"0px"}}>
                  {(busy == "edit" || busy == "editing") ?
                    <p>
                      Enter your email address below to save this sign and continue 
                      editing it in our sign designer.
                    </p>
                  :
                    <p>
                      Enter your email address below to save this sign and get 
                      an updated price.
                    </p>
                  }
                  <EmailField 
                    value={email} 
                    label="Your Email"
                    wide
                    disabled={busy == "saving"}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    autoComplete="email"
                  />
                  <div style={{display:"flex", marginTop: "10px"}}>
                      <HoverButton 
                          label="Save Sign"
                          color="green"
                          wide
                          busy={busy=="saving" || busy=="editing"}
                          disabled={busy=="saving" || busy=="editing" || email==""}
                          icon={faSave}
                          onClick={() => {
                            setBusy(busy == "edit" ? "editing":"saving");
                            notifyBuilder("savesign", {email})
                          }}
                      />                            
                      <HoverButton 
                          label="Cancel"
                          onClick={() => setBusy("")}
                      />                            
                  </div>
                </div>
              :
                <SignMenu>
                    <MenuItem style={{padding: "0px"}}>
                      <div>
                        {sign.size && 
                          <table style={{marginBottom:"12px"}}>
                              <tbody>
                                  <tr>
                                    <td>Size:</td>
                                      <td>
                                          {multiGroupSign ? 
                                            <>
                                              <HoverButton 
                                                  tooltip="Decrease"
                                                  color="blue"
                                                  icon={faArrowDown}
                                                  onClick={() => onSignChange("size",-1)}
                                              />
                                              <HoverButton 
                                                  tooltip="Increase"
                                                  color="blue"
                                                  icon={faArrowUp}
                                                  onClick={() => onSignChange("size",+1)}
                                              />                          
                                            </>
                                          :
                                            <SelectField 
                                                value={sign.size}
                                                size="small"
                                                disabled={busy.length > 0}
                                                options={sign.sizes.map((s, index) => {
                                                  return {
                                                    value: s.size, 
                                                    label: s.size
                                                  }
                                                })}
                                                onChange={(e) => onSignChange("size", e.target.value)}
                                            />
                                          }
                                      </td>
                                      <td>
                                        <strong>{sign.size_height}" x {sign.size_width}"</strong>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                        }
                        <table className="colors">
                            <thead>
                                <tr>
                                    {sign.colors.face && <th>Face Color</th>}
                                    {sign.colors.trim && <th>Trim Color</th>}
                                    {sign.colors.side && <th>Side Color</th>}
                                    {sign.colors.mount && <th>Raceway Color</th>}
                                    {sign.colors.lighting && <th>Halo Color</th>}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {sign.colors.face && 
                                        <td 
                                          onClick={() => {
                                            if (busy=="")
                                              setSelected({mode:'color', part: sign.colors.face});
                                          }} 
                                          style={{
                                            backgroundColor: sign.colors.face.color ? "#"+sign.colors.face.color.hex_day : "white",
                                            cursor: busy.length ? "not-allowed":"pointer",
                                          }}
                                        >
                                            &nbsp;
                                        </td>
                                    }
                                    {sign.colors.trim && 
                                        <td 
                                          onClick={() => {
                                            if (busy=="")
                                              setSelected({mode:'color', part: sign.colors.trim});
                                          }} 
                                          style={{
                                            backgroundColor: sign.colors.trim.color ? "#"+sign.colors.trim.color.hex_day : "white",
                                            cursor: busy.length ? "not-allowed":"pointer",
                                          }}
                                        >
                                            &nbsp;
                                        </td>
                                    }
                                    {sign.colors.side &&
                                        <td 
                                          onClick={() => {
                                            if (busy=="")
                                              setSelected({mode:'color', part: sign.colors.side});
                                          }} 
                                          style={{
                                            backgroundColor: sign.colors.side.color ? "#"+sign.colors.side.color.hex_day : "white",
                                            cursor: busy.length ? "not-allowed":"pointer",
                                          }}
                                        >
                                            &nbsp;
                                        </td>
                                    }
                                    {sign.colors.mount &&
                                        <td 
                                          onClick={() => {
                                            if (busy=="")
                                              setSelected({mode:'color', part: sign.colors.mount});
                                          }} 
                                          style={{
                                            backgroundColor: sign.colors.mount.color ? "#"+sign.colors.mount.color.hex_day : "white",
                                            cursor: busy.length ? "not-allowed":"pointer",
                                          }}
                                        >
                                            &nbsp;
                                        </td>
                                    }    
                                    {sign.colors.lighting &&
                                        <td 
                                          onClick={() => {
                                            if (busy=="")
                                              setSelected({mode:'color', part: sign.colors.lighting});
                                          }} 
                                          style={{
                                            backgroundColor: sign.colors.lighting.color ? "#"+sign.colors.lighting.color.hex_day : "white",
                                            cursor: busy.length ? "not-allowed":"pointer",
                                          }}
                                        >
                                            &nbsp;
                                        </td>
                                    }                                                                                           
                                </tr>
                            </tbody>
                        </table>
                      </div>
                    </MenuItem>

                    <MenuItem color="white" style={{ padding: "0px 5px", marginBottom: "0px"}}>
                        
                        { (sign && sign.estimate && !dirty) && <Price>{formatPrice(sign.estimate)}</Price> }
                        
                        {(dirty || !sign.estimate) ? 
                            <Button 
                                label="Save To See Price"
                                color="green"
                                wide
                                busy={busy=="saving"}
                                icon={faSave}
                                onClick={() => setBusy("save")}
                            />
                        :
                            <Button 
                                label="Buy"
                                color="green"
                                wide
                                style={{marginLeft: "15px"}}
                                busy={busy=="adding"}
                                icon={faShoppingCart}
                                onClick={onAddToCart}
                            />
                        }
                    </MenuItem>

                    <MenuItem color="white" style={{padding: "0px 5px", marginTop: "0px", marginBottom: "0px"}}>
                      <HoverButton
                        label="Open in Sign Designer"
                        color="orange"
                        wide 
                        busy={busy=="edit"}
                        icon={faPencilAlt}
                        to={"/build/?uid=" + sign.uid + "&template=" + (isTemplate ? 1:0)}
                        onClick={(e) => {
                          // if (dirty || isTemplate) {
                          //   e.preventDefault();
                          //   setBusy("edit");
                          // }
                          // else {
                          window.location = "/build/?uid=" + sign.uid + "&template=" + (isTemplate ? 1:0);
                          // }
                        }}
                      />
                    </MenuItem>

                    <MenuItem color="white" style={{padding: "0px 5px", marginTop: "0px"}}>
                      <HoverButton
                        label="Free Design Help"
                        color="blue"
                        wide 
                        icon={faUser}
                        onClick={(e) => {
                          let url = "/sign-price-request/?from=SignTemplate";

                          if (isTemplate) {
                            url += "&description=Template+Sign+" + sign.id
                          } else {
                            url += "&description=Sign+Design+" + sign.id + "&sid=" + sign.id
                          }

                          window.location = url;
                        }}
                      />
                    </MenuItem>

                    <li style={{padding: "10px"}}>
                        Total Price for complete sign ready to install.
                        <div style={{marginTop:"10px"}}>
                            Order Today Ships FREE: <ShipDate format="long" />
                        </div>
                    </li>
                </SignMenu>
              }        
            </>
          }
          </div>
        </Layout2Columns>
      </Container>

      {sign.id && 
        <Content>
        <Container>
          <SignDetails 
              sign={sign} 
              noSummary
              customPanel={!isMobile && sign.products && sign.products.length > 0}
              customPanelTitle="Alternative Products"
              customPanelContent={
                ((sign.products && sign.products.length > 0) && 
                  <ProductMenu>
                      {sign.product.related.map((product, index) => (
                          <div
                            key={index}
                            onClick={() => {
                              if (busy == "") 
                                onSignChange("product", product);
                            }}
                            style={{cursor: busy.length ? "not-allowed":"pointer"}}
                            className={sign.product.id == product.id ? "selected":""}                                  
                          >
                              <GoogleImage 
                                  root 
                                  src={"2020/products/"+ (product.parent_name || product.name) +"/productmed.jpg?v6"} 
                                  required 
                                  lazy
                                  alt={product.title} 
                                  style={{
                                      maxWidth: "120px",
                                      width: "100%"
                                  }}
                              />    

                              <h4>{product.title}</h4> 
                          </div>   
                      ))}
                  </ProductMenu>  
                )
              }
          />
        </Container>
        </Content>
      }

        {selected.mode == "color" && 
            <ColorsDrawer 
                title={selected.part.title + " Color"}
                part={selected.part}
                onColor={(color) => {
                    onSignChange(selected.part.type_name, color);
                    setSelected({mode:""});
                }}
                onClose={() => setSelected({mode:""})} 
            />
        }

        { (error && error != "Unable to locate the requested sign.") &&
            <MajorError 
              error={error} 
              log={error}
              onClose={() => setError(null)}
            />
        }
    </>
  )
}

export default SignPage
